<template>
  <v-container class="app-context" fluid>
    <div class="context flex">
      <div class="hospital-list fill-width-list">
        <v-card elevation="0" class="mr-5">
          <v-row>
            <v-col cols="auto">
              <v-card-title
                class="pl-0 text-h5 reform_primary--text note-title"
              >
                발신 쪽지함
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
        <v-container fluid class="ma-0 pa-0">
          <v-row no-gutters>
            <v-col cols="6" md="2" class="pr-1 inq-Type-col">
              <v-select
                class="inq-Type"
                :items="listStatus.inqType.options"
                v-model="listStatus.inqType.value"
                rounded
                outlined
                dense
                loader-height="20"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" class="pl-1 mr-auto inq-word-col">
              <v-text-field
                class="inq-word"
                color="black"
                outlined
                rounded
                dense
                append-icon="mdi-magnify"
                v-model="listStatus.inqWord"
                placeholder="검색어를 입력해주세요."
                @keyup.enter="reload(currentTab)"
                @click:append="reload(currentTab)"
              />
            </v-col>
            <v-col class="search-col">
              <v-row justify="end" no-gutters>
                <v-col
                  cols="12"
                  md="auto"
                  style="line-height: 40px"
                  class="text-left mr-2 search-text"
                >
                  기간 검색
                </v-col>
                <v-col md="3" class="start-inq-dt-col">
                  <v-text-field
                    class="start-inq-dt"
                    outlined
                    rounded
                    dense
                    color="black"
                    placeholder=""
                    v-model="listStatus.startInqDt"
                    @input="genetateHyphen(listStatus.startInqDt, 'start')"
                  />
                </v-col>
                <v-col
                  cols="auto"
                  style="line-height: 40px"
                  class="pr-1 pl-1 search-and"
                  ><span> ~ </span></v-col
                >
                <v-col md="3" class="end-inq-dt-col">
                  <v-text-field
                    class="end-inq-dt"
                    outlined
                    rounded
                    dense
                    color="black"
                    placeholder=""
                    v-model="listStatus.endInqDt"
                    @input="genetateHyphen(listStatus.endInqDt, 'end')"
                  />
                </v-col>
                <v-col cols="auto" class="pl-1 search-btn">
                  <v-btn
                    dark
                    rounded
                    height="40px"
                    color="reform_primary"
                    elevation="0"
                    @click="reload(currentTab)"
                    style="width: 90px; cursor: pointer"
                  >
                    검색
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="list.length > 0 && !isChildLoading" class="note-list-col">
          <div class="scroll-body">
            <div class="scroll-content">
              <div>
                <v-row no-gutters style="font-size: 19px; color: gray">
                  <v-col cols="12">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col class="grey--text" cols="1">
                          <span> </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 수신인 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 병원명 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 제목 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="3">
                          <span> 내용 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> 발신일 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> </span>
                        </v-col>
                      </v-row>
                    </v-container>
                    <span style="border-bottom: 1px solid lightgray"> </span>
                  </v-col>
                </v-row>
                <v-row
                  style="font-size: 18px; border-bottm: 1px solid lightgray"
                  class="note-list"
                  v-for="(listItem, index) in list"
                  :key="index + 'K'"
                  @click="goDetail(listItem)"
                  no-gutters
                >
                  <v-col>
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col cols="1" class="text-center">
                          <img
                            v-if="listItem.openYn === 'N'"
                            src="@/assets/images/icons/mail_outline_black_24dp.svg"
                            :class="{
                              attched:
                                listItem.attchId !== '' &&
                                listItem.attchId === null,
                            }"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/drafts_black_24dp.svg"
                            :class="{
                              attched:
                                listItem.attchId !== '' &&
                                listItem.attchId === null,
                            }"
                          />
                          <img
                            v-if="listItem.attchId"
                            src="@/assets/images/icons/attach_file_black_24dp.svg"
                            style="margin-left: 10px"
                          />
                        </v-col>
                        <v-col cols="2" class="text-center">
                          {{ listItem.recptDrNm }}
                        </v-col>
                        <v-col cols="2" class="text-center">
                          {{ listItem.recptDrHospNm }}
                        </v-col>
                        <v-col cols="2">
                          {{ listItem.title }}
                        </v-col>
                        <v-col cols="3">
                          {{ listItem.conts }}
                        </v-col>
                        <v-col cols="2" class="text-center">
                          <v-row>
                            <v-col cols="7" class="text-center">
                              {{ listItem.creDt.substr(0, 10) }}
                            </v-col>
                            <v-col cols="5">
                              <img
                                src="@/assets/images/icons/launch_black_24dp.svg"
                                style="margin-left: 30px"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
        <div class="pa-0 ma-0" v-else>
          <div class="scroll-body">
            <div class="scroll-content">
              <div>
                <v-row no-gutters style="font-size: 19px; color: gray">
                  <v-col cols="12">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col class="grey--text" cols="1">
                          <span> </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 수신인 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 병원명 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 제목 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="3">
                          <span> 내용 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> 발신일 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> </span>
                        </v-col>
                      </v-row>
                    </v-container>
                    <span style="border-bottom: 1px solid lightgray"> </span>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-col cols="12" class="text-center logo-wrap-col">
              <div class="item">
                <div class="logo-wrap">
                  <img src="@/assets/images/logo_unplugged.svg" />
                </div>
                <h4>조회결과 없음</h4>
              </div>
            </v-col>
          </div>
        </div>
        <v-row align="center" class="mt-5">
          <v-col cols="3"> </v-col>
          <v-col cols="6" class="text-center pagination-wrap pa-0 ma-0">
            <p
              class="navigation-act prev"
              v-if="listStatus.first > 5"
              @click="navigatorAct('prev')"
            >
              <!-- Prev -->
              <v-icon> mdi-chevron-left </v-icon>
            </p>
            <ul id="pagination">
              <li
                v-for="(pagination, index) in listStatus.currentPaginationList"
                :key="index + 'j'"
                @click="loadReferList(pagination, index)"
              >
                <p>{{ pagination }}</p>
              </li>
            </ul>
            <p
              class="navigation-act next"
              v-if="
                listStatus.next > 5 &&
                listStatus.pageCount < listStatus.totalPage &&
                listStatus.last !== listStatus.totalPage
              "
              @click="navigatorAct('next')"
            >
              <!-- Next -->
              <v-icon> mdi-chevron-right </v-icon>
            </p>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <div class="spinner" v-if="isChildLoading">
          <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
            <beat-loader
              :loading="isChildLoading"
              :color="loader.color"
              :size="loader.size"
            ></beat-loader>
          </transition>
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-container>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions } from "vuex";
import BeatLoader from "vue-spinner/src/BeatLoader";

export default {
  components: {
    DefaultPopup,
    BeatLoader,
  },
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      popupSet: {},
      currentTab: 1,
      tab: [
        {
          title: "수신",
        },
        {
          title: "발신",
        },
        {
          title: "쪽지",
        },
      ],
      searchOpt: {
        receiveOrder: 1, // default 옵션,
      },
      inqStatus: [
        {
          flag: true,
          title: "모든 사용자",
        },
        {
          flag: false,
          title: "사용자 선택",
        },
      ],
      listStatus: {
        startInqDt: null,
        fakeStartInqDt: null,
        fakeEndInqDt: null,
        endInqDt: null,
        inqType: {
          options: ["제목", "내용", "발송인"],
          value: "제목",
        },
        inqWord: "",
        inqId: null,
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      list: [],
      listHeader: ["발송자", "발신일", "제목", "내용", "첨부파일"],
      setCount: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    //   window.onpopstate = () => {
    //     this.handleGoback();
    //   };
    // }
    // console.log("his", history.pushState);
    this.defaultSet();
    this.currentTab = 1;
    this.reload(this.currentTab);
  },
  mounted() {
    //document.querySelector(".default-opt").classList.add("noneEffect");
  },
  computed: {
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
    genetateHyphen() {
      return (numData, isStart) => {
        // console.log("하이픈 진입");
        const number = numData.toString().replace(/[^0-9]/g, "");
        // console.log("number-length", number.length);
        let generatedNum = "";
        if (number.length < 5) {
          return number;
        } else if (number.length < 7) {
          generatedNum += number.substr(0, 4);
          generatedNum += "-";
          generatedNum += number.substr(4);
        } else if (number.length < 9) {
          generatedNum += number.substr(0, 4);
          generatedNum += "-";
          generatedNum += number.substr(4, 2);
          generatedNum += "-";
          generatedNum += number.substr(6);
        } else {
          generatedNum = "";
        }
        // console.log("gene", generatedNum);
        if (isStart === "start") {
          this.listStatus.startInqDt = generatedNum;
        } else {
          this.listStatus.endInqDt = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapMutations("note", ["SET_NOTE_IDENTITY"]),
    ...mapActions("note", ["RECPT_LIST", "TRANS_LIST"]),
    writeMessage() {
      const sendAppheaderItem = {
        method: "writeMessage",
        item: "noteList",
      };
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
    // listReaded(order) {
    //   document.querySelector(".tabs__header").childNodes[order].click();
    // },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    caculateDate(number) {
      const date = new Date(number);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let monthValue;
      let today;
      if (String(month).length < 2) {
        monthValue = String(year) + "-0" + String(month);
      } else {
        monthValue = String(year) + "-" + String(month);
      }
      const material = monthValue + "-";
      const material2 = monthValue + "-0";

      if (String(day).length < 2) {
        today = String(material2 + day);
      } else {
        today = String(material + day);
      }
      return today;
    },
    goDetail(param) {
      param.player = "발신자";
      this.SET_NOTE_IDENTITY(param);
      this.$router.push("/v/mypage/noteDetail").catch(() => {});
    },
    /** 시작일자 종료일자가 0 이면 자동으로 됩니다. 01월01일, 투데이 넣어줌 */
    checkStartInqDt() {
      // console.log("현재길이", this.listStatus.startInqDt.length);
      if (this.listStatus.startInqDt.length >= 11) {
        this.listStatus.startInqDt = "";
      }
    },
    checkEndInqDt() {
      // console.log("현재길이", this.listStatus.endInqDt.length);
      if (this.listStatus.endInqDt.length >= 11) {
        this.listStatus.endInqDt = "";
      }
    },
    pickUserType(order) {
      for (let i = 0; i < this.inqStatus.length; i++) {
        this.inqStatus[i].flag = false;
        if (order === i) {
          this.inqStatus[i].flag = true;
        }
      }
      this.listStatus.inqId = null;
    },
    collectDate(dateValue) {
      if (dateValue === "start") {
        return new Date().getFullYear() + "-01-01";
      } else if (dateValue !== undefined) {
        date = new Date(dateValue);
      } else {
        date = new Date();
      }
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let monthValue;
      let today;
      let date;
      if (String(month).length < 2) {
        monthValue = String(year) + "-0" + String(month);
      } else {
        monthValue = String(year) + "-" + String(month);
      }
      const material = monthValue + "-";
      const material2 = monthValue + "-0";

      if (String(day).length < 2) {
        today = String(material2 + day);
      } else {
        today = String(material + day);
      }
      return today;
    },
    defaultSet() {
      const startDate = this.collectDate("start");
      /**
      this.listStatus.startInqDt = startDate.replace(/-/gi, "");
      this.listStatus.endInqDt = this.collectDate().replace(/-/gi, "");
       */
      this.listStatus.startInqDt = startDate;
      this.listStatus.endInqDt = this.collectDate();
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/").catch(() => {});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },

    initListStatus() {
      this.listStatus.totalDataCnt = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        // console.log(
        //   "Ds",
        //   document.querySelector("#pagination").childNodes[k].childNodes[0]
        //     .textContent
        // );
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadReferList(currentPage, order) {
      this.list = [];
      const list = [];
      /** const list = [];
       * 페이지네이션이 복수기에 parentNode 처리해줘야함
       */
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();
      // if (this.listStatus.totalDataCnt >= 1) {
      //   document
      //     .querySelector("#pagination")
      //     .childNodes[order].classList.add("active");
      // }
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
        startInqDt: this.listStatus.startInqDt.replace(/-/gi, ""),
        endInqDt: this.listStatus.endInqDt.replace(/-/gi, ""),
        inqType: this.listStatus.inqType.value === "제목" ? 1 : 2,
        inqWord: this.listStatus.inqWord,
        receiveOrder: 1,
      };
      // console.log("this.searchOpt.receiveOrder", this.searchOpt.receiveOrder);
      if (this.listStatus.inqId !== null) {
        searchOpt.inqId = this.listStatus.inqId;
      }
      if (this.searchOpt.receiveOrder === 0) {
        try {
          await this.RECPT_LIST(searchOpt).then((data) => {
            // console.log("RECPT_LIST_DATA", data);
            this.listStatus.totalDataCnt = data.data.param.totalCount;
            for (let i = 0; i < data.data.list.length; i++) {
              list.push(data.data.list[i]);
            }
            this.listHeader[0] = "발송자";
            setTimeout(() => {
              this.list = list;
              this.SET_CHILD_LOADER(false);
              // this.preParePagination();
              this.listStatus.currentPage = order + 1;
            }, 100);

            setTimeout(() => {
              if (this.listStatus.totalDataCnt >= 1) {
                document
                  .querySelector("#pagination")
                  .childNodes[order].classList.add("active");
              }
            }, 200);

            /**
            for (let i = 0; i < this.GET_RECEIVE_LIST.list.length; i++) {
              list.unshift(this.GET_RECEIVE_LIST.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              this.preParePagination();
            }, 800);
             */
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      } else if (this.searchOpt.receiveOrder === 1) {
        try {
          await this.TRANS_LIST(searchOpt).then((data) => {
            // console.log("TRANS_LIST_DATA", data);
            this.listStatus.totalDataCnt = data.data.param.totalCount;
            for (let i = 0; i < data.data.list.length; i++) {
              list.push(data.data.list[i]);
            }
            this.listHeader[1] = "수신자";
            setTimeout(() => {
              this.list = list;
              this.SET_CHILD_LOADER(false);
              // this.preParePagination();
              this.listStatus.currentPage = order + 1;
            }, 800);

            setTimeout(() => {
              if (this.listStatus.totalDataCnt >= 1) {
                document
                  .querySelector("#pagination")
                  .childNodes[order].classList.add("active");
              }
            }, 200);

            /**
            for (let i = 0; i < this.GET_SEND_LIST.list.length; i++) {
              list.unshift(this.GET_SEND_LIST.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              this.preParePagination();
            }, 800);
             */
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalDataCnt = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      // console.log("totalDataCnt", totalDataCnt);
      // console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      // console.log("totalPage", totalPage);
      // console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      // console.log("last : " + last);
      // console.log("first : " + first);
      // console.log("next :" + next);
      // console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    async calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        await this.$nextTick((_) => {
          this.loadReferList(this.listStatus.last, 4);
        });
        setTimeout(() => {
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            /*console.log(
              "재료",
              document.querySelector("#pagination").childNodes[k].childNodes[0]
                .textContent
            );*/

            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        }, 900);
      } else if (type === "next") {
        await this.loadReferList(this.listStatus.currentPage, 0).then(() => {
          setTimeout(() => {
            document
              .querySelector("#pagination")
              .childNodes[0].classList.add("active");
          }, 1000);
        });
      } else {
        if (this.list.length > 0) {
          await this.$nextTick((_) => {
            const target = document.querySelectorAll(".note-list-col");
            // console.log("current-page", this.listStatus.currentPage);
            // console.log("target", target);
            // console.log("target L", target.childElementCount);
            // console.log("pagenationL", target.querySelector("#pagination"));
            // console.log(
            //   "pagenationL-child",
            //   target.querySelector("#pagination").childNodes[0]
            // );
            if (target !== undefined) {
              function addElsePagination(currentPage) {
                setTimeout(() => {
                  if (currentPage !== null) {
                    if (
                      document.querySelector("#pagination") === null ||
                      document.querySelector("#pagination") === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === null ||
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ].classList === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    document
                      .querySelector("#pagination")
                      .childNodes[currentPage - 1].classList.add("active");
                  } else {
                    return addElsePagination(currentPage);
                  }
                }, 100);
              }
              addElsePagination(this.listStatus.currentPage);
            }
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    async reload(order) {
      //console.log("order",order)
      this.currentTab = order;
      this.initListStatus();
      this.list = [];
      const list = [];
      this.SET_CHILD_LOADER(true);
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
        startInqDt: this.listStatus.startInqDt.replace(/-/gi, ""),
        endInqDt: this.listStatus.endInqDt.replace(/-/gi, ""),
        inqType: this.listStatus.inqType.value === "제목" ? 1 : 2,
        inqWord: this.listStatus.inqWord,
      };

      //발송인 선택 시 값 변경
      if (this.listStatus.inqType.value === "발송인") {
        this.listStatus.inqId = this.listStatus.inqWord;
        searchOpt.inqWord = "";
      }

      if (this.listStatus.inqId !== null) {
        searchOpt.inqId = this.listStatus.inqId;
      }
      // console.log("전송자", searchOpt);
      if (order === 0) {
        try {
          await this.RECPT_LIST(searchOpt).then((data) => {
            // console.log("RECPT_LIST DATA", data.data.param);
            // console.log("RECPT_LIST DATA[0]", data.data.list[0]);
            this.listStatus.totalDataCnt = data.data.param.totalCount;
            for (let i = 0; i < data.data.list.length; i++) {
              list.push(data.data.list[i]);
            }
            this.searchOpt.receiveOrder = 0;
            this.listHeader[0] = "발송자";
            setTimeout(() => {
              this.list = list;
              this.SET_CHILD_LOADER(false);
              this.preParePagination();
            }, 800);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      } else if (order === 1) {
        try {
          await this.TRANS_LIST(searchOpt).then((data) => {
            // console.log("RECPT_LIST DATA", data.data.param);
            // console.log("RECPT_LIST DATA[0]", data.data.list[0]);
            this.listStatus.totalDataCnt = data.data.param.totalCount;
            for (let i = 0; i < data.data.list.length; i++) {
              list.push(data.data.list[i]);
            }
            this.searchOpt.receiveOrder = 1;
            this.listHeader[0] = "수신자";
            setTimeout(() => {
              this.list = list;
              this.SET_CHILD_LOADER(false);
              this.preParePagination();
            }, 800);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
@import "@/assets/css/sideContext";
@import "@/assets/scss/scrollObj";
#app {
  .note {
    max-width: 1240px;
    padding: 35px 0 105px 0;
    min-height: 730px;
  }
  .pagination-wrap {
    position: relative;
    margin: 25px 40px 0 auto;
    width: fit-content;
    display: flex;
    justify-content: center;
    .navigation-act {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "SUIT";
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      color: #000000;
      &.prev {
        margin: 0 12.5px 0 0;
        /**
                &:after {
                    position:absolute;
                    content: "<"
                }
                */
      }
      &.next {
        margin: 0 0 0 12.5px;
        /**
                &:after {
                    position:absolute;
                    content: ">"
                }
                */
      }
    }
    ul#pagination {
      display: flex;
      li {
        //display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 5px;
        cursor: pointer;
        font-family: "SUIT";
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        //line-height: 22px;
        letter-spacing: normal;
        color: #000000;
        min-width: 30px;
        min-height: 26px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        &.active {
          background-color: #146f83;
          p {
            color: #fff;
          }
        }
        &:first-of-type {
          margin: 0;
        }
        p {
          font-family: "SUIT";
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 35px;
          letter-spacing: normal;
          text-align: center;
          color: #000000;
          position: relative;
        }
      }
    }
  }
}
.search-context {
  .search {
    display: flex;
    margin: 0 0 30px 0;
    width: 710px;

    .input-wrap {
      width: calc(100% - 155px);
      padding: 0 15px 0 0;
      border: solid 1px #b7b7b7;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      input {
        border: 0;
        padding: 0 12px;
        font-family: NanumSquareEB;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 55px;
        letter-spacing: normal;
        text-align: left;
        color: #146f83;
        width: 100%;
      }
      .s-icon {
        width: 40px;
        cursor: pointer;
        img {
        }
      }
    }
  }

  .filter {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    .date-wrap {
      display: flex;
      align-items: center;
      margin: 0 30px 0 0;
      min-width: 410px;
      .item {
        display: flex;
        margin: 0 0 0 10px;
        &:first-of-type {
          margin: 0;
        }
        p.title {
          margin: 0 10px 0 0;
          line-height: 32px;
        }
        .input-wrap {
          border: solid 1px #707070;
          background-color: #fafafa;
          width: 105px;
          display: flex;
          align-items: center;
          justify-items: center;
          overflow: hidden;
          input {
            font-family: SUIT;
            padding: 0;
            border: 0;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 24px;
            letter-spacing: normal;
            text-align: center;
            color: #bebebe;
            width: inherit;
          }
        }
      }
    }
    .target {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        margin: 0;
        position: relative;
        min-width: 160px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:first-of-type {
          margin: 0;
        }
        &.active {
          .check {
            display: block;
          }
          p.title {
            color: #146f83;
            padding: 0;
            font-weight: bold;
          }
        }
        .check {
          left: 0;
          margin: 0;
        }
      }
      .user-id {
        position: relative;
        max-width: 198px;
        input {
          font-family: SUIT;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: left;
          color: #474747;
        }
        &:after {
          content: "";
          bottom: -5px;
          position: absolute;
          width: 100%;
          height: 1px;
          background: #707070;
          left: 0;
        }
      }
    }
  }
}
.note-list {
  .note_btn_warp {
    display: flex;
    width: 130px;
    min-width: 130px;
    height: 40px;
    background-color: #146f83;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 89.5%;
    z-index: 1;

    p {
      width: 100%;
      height: 100%;
      position: relative;
      margin-top: 8px;
      cursor: pointer;
    }
    img {
      margin-left: 5px;
      width: 30px;
      height: 40px;
    }
  }
  .tab-wrapper {
    position: relative;
    .spinner {
      position: absolute;
      top: 0;
      width: 100%;
      height: 380px;
    }
  }
  .article-header {
    display: flex;
    .item {
      border: 0px solid #707070;
      border-width: 1px 0px 1px 1px;
      h3.title {
        font-family: SUIT;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 55px;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        width: 100%;
        background: #f7f7f7;
      }
      &:nth-of-type(1) {
        width: 145px;
        border-width: 1px 0px 1px 0px;
      }
      &:nth-of-type(2) {
        width: 115px;
      }
      &:nth-of-type(3) {
        width: 260px;
      }
      &:nth-of-type(5) {
        width: 260px;
      }
      &:nth-of-type(4) {
        width: calc(100% - 780px);
      }
    }
  }
  .list {
    .article {
      display: flex;
      cursor: pointer;
      &:hover {
        background: #eaeaea;
      }
      .item {
        border: 0px solid #707070;
        border-width: 1px 0px 1px 1px;
        display: flex;
        justify-content: center;
        &:nth-of-type(1) {
          width: 145px;
          border-width: 1px 0px 1px 0px;
        }
        &:nth-of-type(2) {
          width: 115px;
        }
        &:nth-of-type(3) {
          width: 260px;
        }
        &:nth-of-type(5) {
          width: 260px;
        }
        &:nth-of-type(4) {
          width: calc(100% - 780px);
        }
        .letter {
          display: flex;
          width: 100%;
          padding: 0 10px;
          img {
            margin: 0 20px 0 0;
          }
        }
        p {
          font-family: SUIT;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 55px;
          letter-spacing: normal;
          text-align: center;
          color: #474747;
          text-overflow: ellipsis;
          max-width: inherit;
          white-space: nowrap;
          overflow: hidden;
          padding: 0 8px;
        }
      }
    }
  }
}
.list-empty {
  height: 380px;
  border: 0px solid #707070;
  border-width: 0px 0 1px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    .logo-wrap {
      margin: 0 auto 10px auto;
      text-align: center;
      max-width: 130px;
      img {
        max-width: 100%;
      }
    }
    h4 {
      font-family: "SUIT";
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: normal;
      text-align: center;
      color: #a2a2a2;
    }
  }
}
.check {
  margin: 0 10px 0 0;
  display: none;
  position: absolute;
  img {
  }
}
p.title {
  font-family: SUIT;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b7b7b7;
  line-height: 29px;
  padding: 0;
}

.note-list:hover {
  background-color: #f5f9fa;
}
.note-list.active {
  background-color: #f5f9fa;
}
.notOpened {
  color: #146f83;
}
.attched {
  margin-right: 35px;
}
.fill-width-list {
  width: 100% !important;
}
</style>
